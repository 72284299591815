<template>
  <div>
    <div id="header-placeholder"></div>
    <header id="header" class="d-flex align-items-center">
      <v-toolbar title flat color="#f5f5f5">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!drawer">
          <v-icon>
            menu
          </v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="menu-title">Azaleahof</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-md-and-down">
          <v-btn color="primary" class="nav-link scrollto active" text plain href="#hero">{{ $t("header.home") }}</v-btn>
          <v-btn color="primary" class="nav-link scrollto active" text plain href="#about">{{ $t("header.about")
          }}</v-btn>
          <v-btn color="primary" class="nav-link scrollto active" text plain href="#services">{{ $t("header.services")
          }}</v-btn>
          <v-btn color="primary" class="nav-link scrollto active" text plain href="#portfolio">{{ $t("header.gallery")
          }}</v-btn>
          <v-btn color="primary" class="nav-link scrollto active" text plain href="#team">{{ $t("header.team") }}</v-btn>
          <v-btn color="primary" class="nav-link scrollto active" text plain href="#contact">{{ $t("header.contact")
          }}</v-btn>
          <v-menu offset-y open-on-hover rounded="0">
            <template v-slot:activator="{ on, attrs }"> <v-btn color="primary" class="nav-link" text plain v-bind="attrs"
                v-on="on"> {{ $t("header.apply") }} </v-btn> </template>
            <v-list>
              <!-- <v-list-item link color="primary" rel="nofollow" target="_blank"
                download="Azaleahof Aansoekvorm vir Toelating.pdf"
                href="/assets/documents/Azaleahof_Aansoekvorm_vir_Toelating.pdf">
                <v-list-item-icon>
                  <v-icon color="primary">download</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="primary">
                  <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form1") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <!-- ADDED GOOGLE FORM -->
              <v-list-item
  href="https://docs.google.com/forms/d/e/1FAIpQLSdc92ItHIqgbVtTY71aPpQTecVOuzoXNPlF0i8QtbuakQraEw/viewform?usp=header"
  target="_blank"
  rel="noopener noreferrer"
  link
  color="primary">
  <v-list-item-icon>
    <v-icon color="primary">download</v-icon>
  </v-list-item-icon>
  <v-list-item-content color="primary">
    <v-list-item-title color="primary" class="menu-button">
      Aanlyn Waglysaansoek
    </v-list-item-title>
  </v-list-item-content>
</v-list-item>

              <!-- <v-list-item link color="primary" rel="nofollow" target="_blank" download="Azaleahof Waglysaansoek.pdf"
                href="/assets/documents/Azaleahof_Waglysaansoek.pdf">
                <v-list-item-icon>
                  <v-icon color="primary">download</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="primary">
                  <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form2") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <!-- <v-list-item link color="primary" rel="nofollow" target="_blank" download="Azaleahof DSlidmaatskapvorm.pdf"
                href="/assets/documents/Azaleahof_DS_lidmaatskapvorm.pdf">
                <v-list-item-icon>
                  <v-icon color="primary">download</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="primary">
                  <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form3") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <!-- <v-list-item link color="primary" @click="this.downloadForms">
                <v-list-item-icon>
                  <v-icon color="primary">download</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="primary">
                  <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form4") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <v-divider vertical></v-divider>
        <div class="locale-switcher">
          <v-btn-toggle borderless group v-model="$i18n.locale" @change="setLocale" mandatory
            background-color="whitesmoke">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text value="en" v-bind="attrs" v-on="on">
                  <!-- <v-img max-height="30" max-width="30" src="assets/img/united-kingdom.png"></v-img> -->
                  EN
                </v-btn>
              </template>
              <span>English</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text value="afr" v-bind="attrs" v-on="on">
                  <!-- <v-img max-height="30" max-width="30" src="assets/img/south-africa.png"></v-img> -->
                  AFR
                </v-btn>
              </template>
              <span>Afrikaans</span>
            </v-tooltip>
          </v-btn-toggle>
        </div>
      </v-toolbar>
      <v-navigation-drawer v-model="drawer" app temporary>
        <v-card flat width="256" class="mx-auto">

            <v-card-title class="menu-title">
            Azaleahof
          </v-card-title>

          <v-list dense nav>
            <v-list-item @click="drawer = !drawer" class="nav-link scrollto active" href="#hero" link>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn color="primary" text plain>{{ $t("header.home") }}</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="drawer = !drawer" class="nav-link scrollto active" href="#about" link>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn color="primary" text plain>{{ $t("header.about") }}</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="drawer = !drawer" class="nav-link scrollto active" link href="#services">
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn color="primary" text plain>{{ $t("header.services") }}</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="drawer = !drawer" class="nav-link scrollto active" href="#portfolio" link>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn color="primary" text plain>{{ $t("header.gallery") }}</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="drawer = !drawer" href="#team" class="nav-link scrollto active">
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn color="primary" text plain>{{ $t("header.team") }}</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="drawer = !drawer" href="#contact" class="nav-link scrollto active">
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn color="primary" text plain>{{ $t("header.contact") }}</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="pa-0 ma-0">
              <v-menu offset-y open-on-hover rounded="0" style="z-index: 1000;">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on" class="ma-0">
                    <v-btn color="primary" class="nav-link" text plain> {{ $t("header.apply") }}
                    </v-btn>
                  </v-list-item>
                </template>
                <v-list>
                  <!-- <v-list-item link color="primary" rel="nofollow" target="_blank"
                    download="Azaleahof Aansoekvorm vir Toelating.pdf"
                    href="/assets/documents/Azaleahof_Aansoekvorm_vir_Toelating.pdf" @click="drawer = !drawer">
                    <v-list-item-icon>
                      <v-icon color="primary">download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content color="primary">
                      <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form1")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
          <!--Added Google Form  -->
          <v-list-item
            href="https://docs.google.com/forms/d/e/1FAIpQLSdc92ItHIqgbVtTY71aPpQTecVOuzoXNPlF0i8QtbuakQraEw/viewform?usp=header"
            target="_blank"
            rel="noopener noreferrer"
            link
            color="primary">
            <v-list-item-icon>
              <v-icon color="primary">download</v-icon>
                </v-list-item-icon>
                  <v-list-item-content color="primary">
                  <v-list-item-title color="primary" class="menu-button">
                    Aanlyn Waglysaansoek
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

                  <!-- <v-list-item link color="primary" rel="nofollow" target="_blank" download="Azaleahof Waglysaansoek.pdf"
                    href="/assets/documents/Azaleahof_Waglysaansoek.pdf" @click="drawer = !drawer">
                    <v-list-item-icon>
                      <v-icon color="primary">download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content color="primary">
                      <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form2")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <!-- <v-list-item link color="primary" rel="nofollow" target="_blank"
                    download="Azaleahof DSlidmaatskapvorm.pdf" href="/assets/documents/Azaleahof_DS_lidmaatskapvorm.pdf"
                    @click="drawer = !drawer">
                    <v-list-item-icon>
                      <v-icon color="primary">download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content color="primary">
                      <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form3")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <!-- <v-list-item link color="primary" @click="this.downloadForms">
                    <v-list-item-icon>
                      <v-icon color="primary">download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content color="primary">
                      <v-list-item-title color="primary" class="menu-button">{{ $t("applyMenu.form4")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-spacer></v-spacer>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list>
        </v-card>
        <template v-slot:append>
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <div class="locale-switcher">
                <v-btn-toggle light borderless group tile v-model="$i18n.locale" @change="setLocale" mandatory
                  background-color="whitesmoke">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text value="en" v-bind="attrs" v-on="on">
                        <!-- <v-img max-height="30" max-width="30" src="assets/img/united-kingdom.png"></v-img> -->
                        EN
                      </v-btn>
                    </template>
                    <span>English</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text value="afr" v-bind="attrs" v-on="on">
                        AFR
                        <!-- <v-img max-height="30" max-width="30" src="assets/img/south-africa.png"></v-img> -->
                      </v-btn>
                    </template>
                    <span>Afrikaans</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
            </v-layout>
          </v-container>
        </template>
      </v-navigation-drawer>
    </header>

    <!-- ADDED GOOGLE FORM DIALOG -->
    <!-- <v-dialog fullscreen v-model="googleFormDialog">
      <v-card style="height: 100%; display: flex; flex-direction: column">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Close</v-btn>
        </v-card-actions>
        <v-card-text style="height: 100%; width: 100%; display: flex; flex-direction: column">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdc92ItHIqgbVtTY71aPpQTecVOuzoXNPlF0i8QtbuakQraEw/viewform?usp=header"
            style="flex-grow: 1; width: 100%; border: none"
          >
            Loading...
          </iframe>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>

export default {
  data: () => ({
    languageOptions: [
      { text: 'Afrikaans', value: 'afr' },
      { text: 'English', value: 'en' }
    ],
    drawer: false,
    collapsed: true,
    googleFormDialog: false
  }),
  mounted() {

    window.addEventListener('scroll', function () {
      var header = document.getElementById('header');
      var placeholder = document.getElementById('header-placeholder');
      var offset = window.visualViewport.height * 99 / 100;

      var headerHeight = header.offsetHeight;
      if (window.scrollY > offset) {
        if (!this.$galleryOpen) {
          header.classList.add('fixed');
          placeholder.style.height = headerHeight + 'px';
        }
      } else {
        header.classList.remove('fixed');
        placeholder.style.height = '0px';
      }
    });

    this.loadLocale()
  },
  methods: {
    // HANDLE GOOGLE FORM DIALOG
    openDialog() {
      this.googleFormDialog = true
    },
    closeDialog() {
      this.googleFormDialog = false
    },
    loadLocale() {
      let savedLocale = localStorage.getItem("locale")
      if (savedLocale) { this.$i18n.locale = savedLocale }
    },
    setLocale() {
      localStorage.setItem("locale", this.$i18n.locale)
    },
    async downloadForms() {
      var hiddenElement2 = document.createElement('a');
      hiddenElement2.href = '/assets/documents/Gedragskode_vir_ACVV_Vrywilligers.pdf';
      hiddenElement2.target = '_blank';
      hiddenElement2.download = 'Gedragskode vir ACVV Vrywilligers.pdf';
      hiddenElement2.click();

      var hiddenElement1 = document.createElement('a');
      hiddenElement1.href = '@/assets/documents/Azaleahof_Vrywilligers_Registervorm.pdf';
      hiddenElement1.target = '_blank';
      hiddenElement1.download = 'Azaleahof Vrywilligers Registervorm.pdf';
      hiddenElement1.click();

      if (this.drawer) { this.drawer = !this.drawer }
    }
  }
};
</script>

<style scoped>

.btn-toggle {
  flex-direction: column;
}
.menu-button {
  color: #0693E3;
  text-transform: uppercase;
  font-size: 14px;
}

.menu-button:hover {
  color: #0692e3cc;
}

.menu-logo {
  max-height: 45px;
}

.menu-title {
  font-family: 'IBM Plex Serif SemiBold', sans-serif;
  font-size: 22px;
  color: #020381;
}


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.locale-switcher {
  padding-right: 2;
  padding-left: 2;

}

.app-menu {
  background: whitesmoke;
}

#header {
  /* position: relative; */
  transition: all 0.5s ease-in-out;
  /* Transition all animatable changes over 300ms */
  z-index: 997;
  background: whitesmoke;
  box-shadow: 0 4px 10px -3px hsla(0, 0%, 75%, 0.5);
}

#header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  background-color: whitesmoke;
}

#header .logo h1 {
  font-size: 36px;
  margin: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

@media (max-width: 992px) {
  #header {
    height: 70px;
  }

  .d-none {
    display: none;
  }
}
</style>
